import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { AdrollAdAccount, CodeNameI, CommonSearchReq, FacebookAudienceI, QuerySearchListResponse } from '@integrations/state/interfaces';
import { HttpErrorResponse } from '@angular/common/http';
import { ListSchema } from '../../../../dist/common/lib/utils/interfaces';

export enum IntegrationsInnerActionTypes {
    //COMMON
    SetAdrollSelectedAccount = '[Adroll] Set Adroll Selected Account',
    SearchQueriesSuccess = '[Integrations] Search Queries Success',
    SearchQueriesError = '[Integrations] Search Queries Error',
    GetRecentAudiencesSuccess = '[Integrations] Get Recent Audiences Success',
    GetRecentAudiencesError = '[Integrations] Get Recent Audiences Error',
    DeleteSiteSuccess = '[Meta Events] Delete Site Success',
    DeleteSiteError = '[Meta Events] Delete Site Error',
    GetPageMinimumViewDurationSuccess = '[Meta Events] Get Page Minimum View Duration Success',
    GetPageMinimumViewDurationError = '[Meta Events] Get Page Minimum View Duration Error',
}

export enum IntegrationsHttpActionTypes {
    //COMMON
    SearchQueries = '[Integrations] Search Queries',
    ClearSearchQueriesResults = '[Integrations] Clear Search Queries Results',
    GetRecentAudiences = '[Integrations] Get Recent Audiences',
    DeleteSite = '[Meta Events] Delete Site',
    GetPageMinimumViewDuration = '[Meta Events] Get Page Minimum View Duration',
}

//HTTP
//COMMON
export const SearchQueries = createAction(IntegrationsHttpActionTypes.SearchQueries, props<{ payload: CommonSearchReq }>());
export const ClearSearchQueriesResults = createAction(IntegrationsHttpActionTypes.ClearSearchQueriesResults);
export const GetRecentAudiences = createAction(IntegrationsHttpActionTypes.GetRecentAudiences, props<{ payload: { searchText?: string, offset?: number, numResults?: number, order?: string, sortBy?: string } }>());
export const DeleteSite = createAction(IntegrationsHttpActionTypes.DeleteSite, props<{ payload: { olyticsSiteId: number, isAdroll?: boolean } }>());
export const GetPageMinimumViewDuration = createAction(IntegrationsHttpActionTypes.GetPageMinimumViewDuration);

// INNER
//COMMON
export const SetAdrollSelectedAccount = createAction(IntegrationsInnerActionTypes.SetAdrollSelectedAccount, props<{ payload: AdrollAdAccount }>());
export const SearchQueriesSuccess = createAction(IntegrationsInnerActionTypes.SearchQueriesSuccess, props<{ payload: ResponseWrapper<QuerySearchListResponse> }>());
export const SearchQueriesError = createAction(IntegrationsInnerActionTypes.SearchQueriesError, props<{ error: BasicResponse }>());
export const GetRecentAudiencesSuccess = createAction(IntegrationsInnerActionTypes.GetRecentAudiencesSuccess, props<{ payload: ResponseWrapper<ListSchema<FacebookAudienceI>> }>());
export const GetRecentAudiencesError = createAction(IntegrationsInnerActionTypes.GetRecentAudiencesError, props<{ payload: BasicResponse | HttpErrorResponse }>());
export const DeleteSiteSuccess = createAction(IntegrationsInnerActionTypes.DeleteSiteSuccess, props<{ payload: ResponseWrapper<BasicResponse> }>());
export const DeleteSiteError = createAction(IntegrationsInnerActionTypes.DeleteSiteError, props<{ payload: BasicResponse }>());
export const GetPageMinimumViewDurationSuccess = createAction(IntegrationsInnerActionTypes.GetPageMinimumViewDurationSuccess, props<{ payload: ResponseWrapper<CodeNameI[]> }>());
export const GetPageMinimumViewDurationError = createAction(IntegrationsInnerActionTypes.GetPageMinimumViewDurationError, props<{ payload: BasicResponse }>());
